import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import ListWithIcons from './components/ListWithIcons'
import CareersTemplate from './template'

function OurBenefits({ data, pageContext }) {
  const { prismicCareersLandingPage } = data

  if (!prismicCareersLandingPage) return null
  const cmsData = prismicCareersLandingPage.data

  return (
    <CareersTemplate
      title="All full-time Algonauts receive:"
      path={pageContext.name}
      data={cmsData}
    >
      {cmsData.body1.map((item, index) => (
        <ListWithIcons
          key={`item_${index}`}
          title={item.primary.list_with_icons_title.text}
          description={
            item.primary.list_with_icons_description
              ? item.primary.list_with_icons_description.text
              : null
          }
          items={item.items.map((listItem) => ({
            icon: listItem.list_with_icons_item_icon.url,
            text: listItem.list_with_icons_item_description.text,
          }))}
          twoColumns={item.primary.list_with_icons_use_two_columns}
          useBackground={item.primary.list_with_icons_use_background_color}
        />
      ))}
    </CareersTemplate>
  )
}

export default withPreview(OurBenefits)

export const pageQuery = graphql`
  query CareersBenefitsQuery($locale: String!) {
    prismicCareersLandingPage(lang: { eq: $locale }) {
      data {
        bottom_banner_link_text {
          text
        }
        bottom_banner_link_url {
          url
        }
        bottom_banner_text {
          text
        }
        mantel_description {
          text
        }
        mantel_image {
          url
        }
        mantel_link_text {
          text
        }
        mantel_link_url {
          url
        }
        mantel_title {
          text
        }
        navigation_items {
          navigation_item_link_text {
            text
          }
          navigation_item_link_url {
            text
          }
        }
        body1 {
          ... on PrismicCareersLandingPageBody1ListWithIcons {
            items {
              list_with_icons_item_description {
                text
              }
              list_with_icons_item_icon {
                url
              }
            }
            primary {
              list_with_icons_description {
                text
              }
              list_with_icons_title {
                text
              }
              list_with_icons_use_background_color
              list_with_icons_use_two_columns
            }
            slice_type
          }
        }
      }
    }
  }
`
